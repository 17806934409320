<template>
	<div>
		<a-row type="flex" :gutter="24">
			<a-col :span="24" :md="24" class="mb-24">
				<CardTransactions  :more="true"/>
			</a-col>
		</a-row>
	</div>
</template>
<script>
	import CardTransactions from "@/components/Cards/CardTransactions"
	export default ({
		components: {
			CardTransactions,
		},
	})
</script>